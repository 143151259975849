import { faAddressBook, faClock, faUser, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

const momentTimezone = require( 'moment-timezone' );

export default function TrailSession( { handleTrial, selected, therapist, setTherapist, setSelectedTerapist, selectedTherapist, patientDetails, emails,
  setEmails, setOk, ok, setValue, value, focused, setFocused, therp, setTherp ,videoQuality,setVideoQuality} )
{

  const handleTherapist = async () =>
  {
    if ( String( selectedTherapist ).length > 0 )
    {
      setTherp( therapist.find( ( item ) => item.value === selectedTherapist ) );
    }
  };
  useEffect( () =>
  {
    handleTherapist();
  }, [ selectedTherapist ] );

  /* gender options */
  const videoOptions = [
    { value: 'LD', label: "LD" },
    { value: 'SD', label: "SD" } ];
  return (
    <div>
      <div className='bg-success p-2 text-dark bg-opacity-10'>
        <div className='fs-2'>
          Schedule Trial consultation
        </div>
        <div className='card'>
          <div className='card-header text-center bg-warning'>
            Assign Therapist
          </div>
          <div className='row'>
            <div className='col-12'>
              <form
              // onSubmit={handleTrial}
              >
                <div className='mb-3 ms-1'>
                  <label><b>Therapists:</b></label>
                  <Select
                    className='form-control'
                    placeholder={"select therapist"}
                    options={therapist}
                    onChange={setSelectedTerapist}
                    value={selectedTherapist}
                    bordered={null}
                  />

                </div>
                {/* {selectedTherapist ? ( <div className='float-end'>
                <button className='btn btn-success'>Submit</button>
              </div> ) : ( <></> )} */}

              </form>
            </div>
          </div>
        </div>

        <div>
          <div className='card'>
            <div className='card-header fs-6 bg-warning'>
              Request Details
            </div>
            <div className='card-body'>
              <div>
                <div className='row'>
                  <div>
                    <FontAwesomeIcon icon={faUser} />{"   "}{patientDetails.name}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faAddressBook} />{"  "}{patientDetails.email}{"  |  "}{patientDetails.phone}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faUserDoctor} />{"  "}{therp.label}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faClock} />{"  "}{momentTimezone.tz( patientDetails.timezone ).format( 'LLL' )}{" | "} ( PATIENT'S CURRENT LOCAL TIME )
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card mt-3'>
            <div className='card-header fs-6 bg-warning'>
              PLEASE SELECT ACCORDING TO PATIENT'S LOCAL TIME
            </div>
            <div className='card-body'>
              <div>
                <div className='row'>
                  <label>Select Date Time</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={[ 'DateTimePicker' ]}>
                      <DateTimePicker
                        label="Basic date time picker"
                        disablePast
                        onChange={( newValue ) => setValue( newValue )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className='row mt-2'>
                  <label>Video Quality<sup style={{ color: "red" }}> *</sup> </label>
                  <Select
                    bordered={false}
                    className='form-control'
                    placeholder={"Please Select video quality"}
                    options={videoOptions}
                    value={videoQuality}
                    onChange={setVideoQuality}
                  />
                </div>
                <div className='mt-2'>
                  <label>
                    Add Guests
                  </label>
                  <ReactMultiEmail
                    placeholder='Input guest emails'
                    emails={emails}
                    onChange={( _emails ) =>
                    {
                      setEmails( _emails );
                    }}
                    autoFocus={true}
                    onFocus={() => setFocused( true )}
                    onBlur={() => setFocused( false )}
                    getLabel={( email, index, removeEmail ) =>
                    {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span data-tag-handle onClick={() => removeEmail( index )}>
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
                <div>
                  <div className='float-end mt-2'>
                    {String( selectedTherapist ).length > 0 && String( value ).length > 0 ? ( <button
                      onClick={() =>
                      {
                        setOk( true );
                        handleTrial();
                      }}
                      className={( () =>
                      {
                        if ( ok )
                        {
                          return ( 'btn btn-sm btn-primary disabled' );
                        } else
                        {
                          return ( 'btn btn-sm btn-primary' );
                        }
                      } )()}
                    >
                      Submit
                    </button> ) : ( <></> )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
