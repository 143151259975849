import { faBed, faMobile, faHospitalUser, faUmbrella, faFileInvoice, faPersonDotsFromLine, faHandHoldingHeart, faSearch, faHospitalAlt, faCalendarAlt, faL, faVideo, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { MyPagination } from '../../component/pagination/MyPagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../component/context/AuthContext';
import { BrowserView, MobileView } from 'react-device-detect';
import { DatePicker, Modal, Select, Tooltip } from "antd";
import React, { useEffect, useId, useState } from 'react';
import DiagnosticViewModal from './DiagnosticViewModal';
import ViewSessionDetails from './ViewSessionDetails';
import Layout from '../../component/layout/Layout';
import Pagination from '@mui/material/Pagination';
import { useMediaQuery } from 'react-responsive';
import AssignTherapist from './AssignTherapist';
import AddMoreMoneyIPD from './AddMoreMoneyIPD';
import { Link, json } from 'react-router-dom';
import PaymentHistory from './PaymentHistory';
import PatientDetails from './PatientDetails';
import AssignPackage from './AssignPackage';
import PackageDetail from './PackageDetail';
import DocumentsView from './DocumentsView';
import ScheduleCall from './ScheduleCall';
import TrailSession from './TrailSession';
import FeedBackList from './FeedBackList';
import AddMoreMoney from './AddMoreMoney';
import { DateRangePicker } from "rsuite";
import Card from 'react-bootstrap/Card';
import Stack from '@mui/material/Stack';
import { toast } from 'react-hot-toast';
import MonthPicker from './MonthPicker';
import RoomDetails from './RoomDetails';
import isAfter from 'date-fns/isAfter';
import "rsuite/dist/rsuite.min.css";
import Feedback from './Feedback';
import MrNoPage from "./MrNoPage";
import Invoice from './Invoice';
import Refund from './Refund';
import moment from 'moment';
import uniqid from 'uniqid';
import dayjs from 'dayjs';
import axios from 'axios';
import { uid } from 'uid';

export default function PatientMaster()
{
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const [ auth ] = useAuth();

  const [ page, setPage ] = useState( 1 );
  const [ type, setType ] = useState( '' );
  const [ flag, setFlag ] = useState( true );
  const [ dates, setDates ] = useState( [] );
  const [ query, setQuery ] = useState( '' );
  const [ filter, setFilter ] = useState( '' );
  const [ userId, setUserId ] = useState( '' );
  const [ endDate, setEndDate ] = useState( null );
  const [ patients, setPatients ] = useState( "" );
  const [ selected, setSelected ] = useState( "" );
  const [ pageCount, setPageCount ] = useState( 0 );
  const [ visible, setVisible ] = useState( false );
  const [ typeIndex, setTypeIndex ] = useState( 0 );
  const [ hospitals, setHospitals ] = useState( [] );
  const [ therapist, setTherapist ] = useState( "" );
  const [ hospitalIndex, setHospitalIndex ] = useState( 0 );
  const [ startDate, setStartDate ] = useState( new Date() );
  const [ adminHospitals, setAdminHospital ] = useState( "" );
  const [ therapistArray, setTherapistArray ] = useState( [] );
  const [ patientHospital, setPatientHospital ] = useState( '' );
  const [ selectedPackage, setSelectedPAckage ] = useState( "" );
  const [ defaultHospital, setDefaultHospital ] = useState( '' );
  const [ therapistOption, setTherapistOptions ] = useState( [] );
  const [ selectedHospital, setSelectedHsopital ] = useState( '' );
  const [ hospitalPackages, setHospitalPackages ] = useState( "" );
  const [ selectedTherapist, setSelectedTerapist ] = useState( "" );
  const [ price, setPrice ] = useState( 0 );
  const [ meta, setMeta ] = useState( [ "" ] );
  const [ advance, setAdvance ] = useState( 0 );
  const [ duration, setDuration ] = useState( 0 );
  const [ paymentId, setPaymentId ] = useState( '' );
  const [ advanceNew, SetAdvanceNew ] = useState( 0 );
  const [ documentID, setDocumentID ] = useState( '' );
  const [ finalAmount, setFinalAmount ] = useState( 0 );
  const [ totalAmount, setTotalAmount ] = useState( 0 );
  const [ modalFlag, setModalFlag ] = useState( false );
  const [ sessionDate, setSessionDate ] = useState( '' );
  const [ sessionList, setSessionList ] = useState( [] );
  const [ flag_dummy, setFlag_dummy ] = useState( false );
  const [ patientDetail, setPatientDetail ] = useState( '' );
  const [ selectedImage, setSelectedImage ] = useState( [] );
  const [ paymentHistory, setPaymentHistory ] = useState( [] );
  const [ selectedPatient, setSelectedPatient ] = useState( "" );
  const [ selectedSession, setSelectedSession ] = useState( '' );
  const [ amountDiscounted, setAmountDiscounted ] = useState( 0 );
  const [ patientCondition, setPatientCondition ] = useState( '' );
  const [ packagesOptions, setPackagesOptions ] = useState( [ "" ] );
  const [ due, setDue ] = useState( 0 );
  const [ ok, setOk ] = useState( false );
  const [ name, setName ] = useState( "" );
  const [ MrNo, setMrNo ] = useState( "" );
  const [ dummy, setDummy ] = useState( '' );
  const [ email, setEmail ] = useState( "" );
  const [ phone, setPhone ] = useState( "" );
  const [ therp, setTherp ] = useState( "" );
  const [ refund, setRefund ] = useState( 0 );
  const [ dueRaw, setDueRaw ] = useState( 0 );
  const [ emails, setEmails ] = useState( [] );
  const [ mailId, setMailId ] = useState( "" );
  const [ discount, setDiscount ] = useState( 0 );
  const [ ipdMoney, setIpdMoney ] = useState( 0 );
  const [ mrFlag, setMrFlag ] = useState( false );
  const [ typeOPD, setTyprOPD ] = useState( true );
  const [ timezone, setTimezone ] = useState( "" );
  const [ feedType, setFeedType ] = useState( "" );
  const [ refundRaw, setRefundRaw ] = useState( 0 );
  const [ focused, setFocused ] = useState( false );
  const [ packageId, setPackageId ] = useState( '' );
  const [ syear, setYear ] = useState( currentYear );
  const [ dateRange, setDateRange ] = useState( '' );
  const [ PatentType, sePatentType ] = useState( '' );
  const [ currency, setCurrency ] = useState( "INR" );
  const [ refundText, sertRefundText ] = useState( '' );
  const [ smonth, setMonth ] = useState( currentMonth );
  const [ showSubmit, setShowSubmit ] = useState( true );
  const [ packageName, setPackageName ] = useState( '' );
  const [ flagDummmy, setFlagDummy ] = useState( false );
  const [ packageData, setPackageData ] = useState( '' );
  const [ patientName, setPatientName ] = useState( '' );
  const [ staticEmail, setStaticEmail ] = useState( "" );
  const [ packageDate, setPackageDate ] = useState( "" );
  const [ roomDetails, setRoomDetails ] = useState( "" );
  const [ paidPrevious, setPaidPrevious ] = useState( 0 );
  const [ packageArray, setPackageArray ] = useState( '' );
  const [ patientType, setPatientType ] = useState( true );
  const [ feedbackList, setFeedBackList ] = useState( "" );
  const [ value, setValue ] = useState( dayjs( new Date() ) );
  const [ patientDetails, setPatientDetails ] = useState( '' );
  const [ currentPackage, setCurrentPackage ] = useState( '' );
  const [ diagnosticData, setDiagnosticData ] = useState( '' );
  const [ diagnosticType, setDiagnostictype ] = useState( '' );
  const [ patientTypeReassign, setPatientTypeReassign ] = useState( '' );
  const [ selectedHospitalNew, setSelectedHospitalNew ] = useState( "" );
  const [ reassignPackageFlag, setReassignPackageFlag ] = useState( false );
  const [ selectHospitalPackage, setSelectHospitalPackage ] = useState( "" );
  const [ amountAfterDiscountRaw, setAmountAfterDiscountRaw ] = useState( 0 );
  const [ edit, setEdit ] = useState( { "name": "", "email": "", "phone": "" } );
  const [ videoQuality, setVideoQuality ] = useState( "SD" );

  let therapistOptions = [];

  const isDesktopOrLaptop = useMediaQuery( { query: '(min-width: 1224px)' } );
  const isTabletOrMobile = useMediaQuery( { query: '(max-width: 1224px)' } );
  const isPortrait = useMediaQuery( { query: '(orientation: portrait)' } );
  const isRetina = useMediaQuery( { query: '(min-resolution: 2dppx)' } );
  const isBigScreen = useMediaQuery( { query: '(min-width: 1824px)' } );

  /* handle Pagination page change */
  const handlePageChange = ( page ) =>
  {
    setPage( page );
  };

  const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

  /* filter options  for admin*/
  const options = [
    // { value: 'self-assign', label: 'self assign' },
    { value: 'assigned-to-others', label: 'assigned to others ' },
    { value: "new", label: 'new' }
  ];
  /* filter option for therapist */
  const optionsForTherapist = [
    { value: 'self-assign', label: 'assigned to self' },
    { value: "new", label: 'new' }
  ];

  const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } =
    DateRangePicker;

  /* hospitals list only for admin */
  const getHospitalRecords = async () =>
  {
    try
    {
      const res = await axios.get( `${server}/api/medi/hospital/get-all-hospital-list-unpaged` );
      if ( res?.data?.success )
      {
        setAdminHospital( res.data.hospitals );
      }
      else
      {
        toast.error( res.data.message );
      }
    } catch ( error )
    {
      toast.error( "something went wrong" );
    }
  };

  let adminHospitalsArray = [];
  useEffect( () =>
  {
    if ( adminHospitals.length )
    {
      Object.values( adminHospitals ).map( ( c ) => (
        adminHospitalsArray.push( { value: c._id, label: c.name } )
      ) );
      setHospitals( adminHospitalsArray );
    }
  }, [ adminHospitals ] );

  /* user id from local storage */
  useEffect( () =>
  {
    setUserId( auth?.user?.id );
    if ( auth.user?.roleName !== 'Admin' )
    {
      if ( auth?.user?.HospitalArray )
      {
        setHospitals( JSON.parse( auth?.user?.HospitalArray ) );
      }
    }
    else if ( auth.user.roleName === 'Admin' )
    {
      getHospitalRecords();
    }
  }, [ auth?.user ] );

  /* search result handler */
  const handleKeyUp = ( e ) =>
  {
    setQuery( e.target.value );
    if ( ( query.trim().length === 0 ) || ( query.trim().length >= 3 ) ) setPage( 1 ); handleList();
  };

  /* getting patients list */
  const handleList = async () =>
  {
    let filterLength = 0;
    let hospitalLength = 0;
    let res = '';
    let therapistHospitalIds = '';
    auth?.user?.roleName !== 'Admin' ? ( therapistHospitalIds = auth.user.HospitalId ) : ( <></> );
    if ( selectedHospital === undefined )
    {
      setSelectedHsopital( '' );
    }
    try
    {
      setPatients( "" );
      if ( ( filter.length > 0 ) )
      {
        if ( therapistHospitalIds.length > 0 )
        {
          if ( selectedHospital.length === 0 )
          {
            res = await axios.get( `${server}/api/medi/patient/list-patients?filter=${filter}&id=${userId}&page=${page}&q=${query}&dateRange=${dateRange}&ther_hosp_ID=${therapistHospitalIds}` );
          }
          else
          {
            if ( selectedHospital === undefined )
            {
              setSelectedHsopital( "" );
            }
            res = await axios.get( `${server}/api/medi/patient/list-patients?filter=${filter}&id=${userId}&hospital=${selectedHospital}&dateRange=${dateRange}&page=${page}&q=${query}&ther_hosp_ID=${therapistHospitalIds}` );
          }
        }
        else
        {
          if ( selectedHospital.length === 0 )
          {
            res = await axios.get( `${server}/api/medi/patient/list-patients?filter=${filter}&id=${userId}&page=${page}&q=${query}&dateRange=${dateRange}` );
          }
          else
          {
            if ( selectedHospital === undefined )
            {
              setSelectedHsopital( "" );
            }
            res = await axios.get( `${server}/api/medi/patient/list-patients?filter=${filter}&id=${userId}&hospital=${selectedHospital}&page=${page}&q=${query}&dateRange=${dateRange}` );
          }
        }
        if ( res.data.success )
        {
          if ( res.data.count > 0 )
          {

            setPatients( res.data.patients );
            setPageCount( res.data.pagination.pageCount );
            setFlag( false );
            setDefaultHospital( selectedHospital );
            // setTypeIndex( 0 );
            // setHospitalIndex( 0 );
          }
          else
          {
            if ( flag )
            {
              // 
              auth?.user?.roleName === 'Admin' ? ( filterLength = options.length ) : ( filterLength = optionsForTherapist.length );
              hospitals.length > 0 ? ( hospitalLength = hospitals.length ) : ( hospitalLength = 0 );
              if ( typeIndex < filterLength )
              {
                if ( hospitalIndex < hospitalLength )
                {
                  setHospitalIndex( hospitalIndex + 1 );
                }
                else
                {
                  setTypeIndex( typeIndex + 1 );
                  setHospitalIndex( 0 );
                }
              }
            }
          }
        } else
        {
          setPatients( res.data.message );
          setPageCount( res.data.count );
        }
      }
    } catch ( error )
    {
      toast.error( "error hospitalList" );
    }
  };

  useEffect( () =>
  {
    handleList();
  }, [ filter, dateRange ] );


  useEffect( () =>
  {
    handleList();
  }, [ selectedHospital ] );

  /* get patient hospital therapists  */
  const getHospitalTherapist = async () =>
  {
    try
    {
      const res = await axios.get( `${server}/api/medi/user/get-hospital-therapists/${selectedHospital}` );
      if ( res.data.success )
      {
        setTherapist( res.data.therapist );
      }
    } catch ( error )
    {
      toast.error( "something went wrong fetching therapist" );
    }
  };

  useEffect( () =>
  {
    Object.values( therapist ).map( ( c ) => (
      therapistOptions.push( { value: c._id, label: c.name, email: c.email } )
    ) );
    setTherapistOptions( therapistOptions );
  }, [ therapist ] );


  useEffect( () =>
  {
    if ( selectedHospital?.length )
    {
      getHospitalTherapist();
    }
  }, [ selectedHospital ] );


  /* assign therpaist */
  const handletherapistAssign = async ( e ) =>
  {
    e.preventDefault();
    try
    {
      const therapistArray = JSON.stringify( therapistOption.filter( item => item.value === selectedTherapist ) );

      const res = await axios.put( `${server}/api/medi/patient/assign-therapist/${selected}/${selectedTherapist}`, { therapistArray } );
      if ( res.data.success )
      {
        toast.success( res.data.message );
        setVisible( false );
        handleList();
        setSelectedTerapist( "" );
      }
      else
      {
        toast.error( res.data.message );
        setVisible( false );
      }
    } catch ( error )
    {
      toast.error( "something went wrong in assign therpist" );
    }
  };

  /* get hospital packages on select particular hospital */
  const hospitalPackage = async () =>
  {
    try
    {
      const res = await axios.get( `${server}/api/medi/package/get-single-hospital-package/${selectedHospital}` );
      if ( res.data.success )
      {
        if ( res.data?.packages )
        {
          setHospitalPackages( res.data.packages );
        }
      }
    } catch ( error )
    {
      toast.error( "something went wrong fetching packages" );
    }
  };

  const invoiceGenerator = async () =>
  {
    try
    {
      const res = await axios.get( `${server}/api/medi/patient/invoice/${selected}` );
      if ( res.data.success )
      {
        setPatientDetail( res.data.patient );
      }
    } catch ( error )
    {
      toast.error( "something went wrong while invoicing" );
    }
  };

  useEffect( () =>
  {
    if ( selectedHospital?.length )
    {
      setHospitalPackages( "" );
      hospitalPackage();
    }
  }, [ selectedHospital ] );

  /* crating options from hospital packages */
  let hospitalPackagesArray = [];
  useEffect( () =>
  {
    Object.values( hospitalPackages ).map( ( c ) => (
      hospitalPackagesArray.push( { value: c._id, label: c.name, meta: { data: c } } )
    ) );
    setPackagesOptions( hospitalPackagesArray );
  }, [ hospitalPackages.length ] );

  /* assign package to patient */
  const handleAssignPatient = async ( e ) =>
  {
    e.preventDefault();
    try
    {
      const packageArray = JSON.stringify( packagesOptions.filter( item => item.value === selectedPackage ) );
      const dummy1 = packagesOptions.filter( item => item.value === selectedPackage );
      // const sessionDateNew = new Date(sessionDate).toLocaleDateString()
      const sessionDone = 0;
      let arrayAdvance = '';


      let pending = '';
      if ( patientType )
      {
        pending = finalAmount - discount - advance;
        arrayAdvance = [ {
          'id': uid( 16 ), 'date': new Date(), 'addedBy': auth?.user?.name, 'therapistId': auth?.user?.id, 'mrNo': MrNo,
          'packageID': selectedPackage, 'PackageName': dummy1[ 0 ].meta.data.name, momentDate: moment().format(), "packageDate": new Date().toISOString(),
          'duration': dummy1[ 0 ].meta.data.duration, 'price': dummy1[ 0 ].meta.data.price, 'PackageDesc': dummy1[ 0 ].meta.data.desc,
          'totalAmount': finalAmount, 'discount': discount, 'amountAfterDiscount': amountAfterDiscountRaw, 'paidPrevious': paidPrevious,
          'current': Number( advance ), 'due': pending, 'status': 'package-assigned', 'patientType': patientTypeReassign, "currency": currency
        } ];
      }
      else
      {
        arrayAdvance = [ {
          'id': uid( 16 ), 'date': new Date(), 'addedBy': auth?.user?.name, 'mrNo': MrNo,
          'packageID': selectedPackage, 'PackageName': dummy1[ 0 ].meta.data.name, momentDate: moment().format(), "packageDate": new Date().toISOString(),
          'duration': dummy1[ 0 ].meta.data.duration, 'price': 0, 'PackageDesc': dummy1[ 0 ].meta.data.desc,
          'totalAmount': 0, 'discount': 0, 'amountAfterDiscount': 0, 'paidPrevious': 0, 'current': 0, 'due': 0, 'status': 'package-assigned',
          'patientType': patientTypeReassign
        } ];
        pending = 0;
      }
      const packageList = [ {
        'id': uid( 16 ), 'date': new Date(), 'packageID': selectedPackage, 'PackageName': dummy1[ 0 ].meta.data.name, "packageDate": new Date().toISOString(),
        'duration': dummy1[ 0 ].meta.data.duration, 'PackageDesc': dummy1[ 0 ].meta.data.desc, "amountAfterDiscountRaw": amountAfterDiscountRaw, 'mrNo': MrNo,
        'patientType': patientTypeReassign, "currency": currency
        // 'totalAmount': finalAmount, 'discount': discount, 'amountAfterDiscount': amountDiscounted, 'due': pending , 'price': dummy1[0].meta.data.price
      } ];
      const res = await axios.put( `${server}/api/medi/patient/assign-package/${selected}/${selectedPackage}`,
        {
          packageArray, startDate, endDate, duration, price, finalAmount, advance, arrayAdvance, discount, amountAfterDiscountRaw,
          paidPrevious, pending, packageList, sessionDone, patientType, PatentType, MrNo, patientTypeReassign, reassignPackageFlag, currency
        } );
      if ( res.data.success )
      {
        toast.success( res.data.message );
        invoiceGenerator();
        setVisible( false );
        setSelectedPAckage( "" );
        setStartDate( "" );
        setEndDate( '' );
        setMrNo( '' );
        setMrFlag( false );
        setSessionDate( '' );
        setPackageDate( "" );
        setReassignPackageFlag( false );
        setAmountDiscounted( 0 );
        setDiscount( 0 );
        setPatientTypeReassign( '' );
        setPatientType( true );
        if ( PatentType !== 'ipd' )
        {
          setType( 'invoice' );
        }
        handleList();
        setTyprOPD( true );
        setTimeout( () =>
        {
          if ( patientDetail && ( PatentType !== 'ipd' ) )
          {
            setVisible( true );
          }
        }, 3000 );
      }
      else
      {
        toast.error( res.data.message );
      }
    } catch ( error )
    {
      toast.error( "erron handleAssignPatient" );
    }
  };

  /* default run on page load to set filter value default to first index */
  useEffect( () =>
  {
    setFilter( auth?.user?.roleName === 'Admin' ? ( options[ typeIndex ].value ) : ( optionsForTherapist[ typeIndex ].value ) );

  }, [ filter.length === 0, hospitals ] );

  useEffect( () =>
  {
    if ( modalFlag )
    {
      async function run()
      {
        const data = await Promise.all( Object.values( packagesOptions ).filter( item => item.value === selectedPackage ) );
        if ( data.length > 0 )
        {
          if ( typeOPD )
          {
            setDuration( data[ 0 ].meta.data.duration );
            setPrice( data[ 0 ].meta.data.price );
            setFinalAmount( duration * price );
            setPaidPrevious( 0 );
            setDiscount( 0 );
          } else
          {
            setDuration( data[ 0 ].meta.data.duration );
            setPrice( 0 );
            setAdvance( 0 );
            setFinalAmount( 0 );
            setPaidPrevious( 0 );
            setDiscount( 0 );
            setAmountDiscounted( 0 );
          }
        }
      }
      run();
    }
    // console.log( typeOPD );
  }, [ selectedPackage, typeOPD ] );


  useEffect( () =>
  {
    if ( !flagDummmy )
    {
      setFinalAmount( duration * price );
    }
  }, [ duration, price ] );

  const handleAdvance = async ( e ) =>
  {
    e.preventDefault();
    try
    {

      const duee = Number( due ) - Number( advanceNew );
      const amountDis = totalAmount - discount;
      const dummy1 = packageArray;
      const arrayAdvance = [ {
        'id': uid( 16 ), 'date': new Date(), 'addedBy': auth?.user?.name, 'therapistId': auth?.user?.id,
        'packageID': dummy1[ 0 ].meta.data._id, 'PackageName': dummy1[ 0 ].meta.data.name, momentDate: moment().format(), "packageDate": packageDate,
        'duration': dummy1[ 0 ].meta.data.duration, 'price': dummy1[ 0 ].meta.data.price, 'PackageDesc': dummy1[ 0 ].meta.data.desc,
        'totalAmount': totalAmount, 'discount': discount, 'amountAfterDiscount': amountDis, 'paidPrevious': advance, 'current': Number( advanceNew ),
        'due': duee, 'status': 'partial-payment'
      } ];

      const res = await axios.post( `${server}/api/medi/patient/advance/${selected}`, { advanceNew, arrayAdvance, advance, duee } );
      if ( res.data.success )
      {
        setAdvance( res.data.advanceAmount );
        invoiceGenerator();
        setVisible( false );
        SetAdvanceNew( '' );
        setType( 'invoice' );
        setTimeout( () =>
        {
          setVisible( true );

        }, 5000 );
        handleList();
        toast.success( res.data.success );
      }
      else
      {
        toast.error( res.data.message );
      }
    } catch ( error )
    {
      toast.error( "something went wrong on handle advance" );
    }
  };

  /* initiale call to list users */
  useEffect( () =>
  {
    handleList();
  }, [ page ] );

  const handleAssignTherapist = async () =>
  {
    try
    {
      if ( selectedHospitalNew )
      {

        const res = await axios.get( `${server}/api/medi/patient/get-single-hospital/${selectedHospitalNew}` );
        if ( res.data.success )
        {
          setTherapist( res.data.therapist );
        }
      }
    } catch ( error )
    {
      toast.error( "something went wrong assigning therapist" );
    }
  };

  useEffect( () =>
  {
    handleAssignTherapist();
  }, [ selectedHospitalNew ] );

  const handleAssignPackage = async () =>
  {
    try
    {
      if ( selectHospitalPackage )
      {
        const res = await axios.get( `${server}/api/medi/package/get-single-hospital-package/${selectHospitalPackage}` );
        if ( res.data.success )
        {
          if ( res.data?.packages )
          {
            setHospitalPackages( res.data.packages );
          }
        }
      }
    } catch ( error )
    {
      toast.error( "something went wrong assigning package" );
    }
  };

  // console.log( selectHospitalPackage );

  useEffect( () =>
  {
    // handleAssignPatient()
    handleAssignPackage();
  }, [ selectHospitalPackage ] );


  const handleClick = async ( id ) =>
  {
    try
    {
      setPaymentId( id[ 0 ].id );
      const res = await axios.get( `${server}/api/medi/patient/invoice/${selectedPatient}` );
      if ( res.data.success )
      {
        setPatientDetail( res.data.patient );
        setType( 'invoice' );
        setVisible( true );
      }
    } catch ( error )
    {
      toast.error( "something went wrong" );
    }
  };
  // console.log( selectedHospital );
  const handleDocumentClick = async () =>
  {
    try
    {
      if ( documentID )
      {
        const res = await axios.get( `${server}/api/medi/patient/document-view/${selected}/${documentID}` );
        if ( res.data.success )
        {
          setSelectedSession( res.data.session );
          setType( 'documents' );
          setVisible( true );
        }
      }
    } catch ( error )
    {
      toast.error( "something went wrong viewing documents" );
    }
  };

  const handleIPDMoneySubmit = async () =>
  {
    try
    {
      if ( ipdMoney > 0 )
      {
        // console.log( packageDate + 'ooo' );
        const dummy1 = packageArray;
        const arrayAdvance = [ {
          'id': uid( 16 ), 'date': new Date(), 'addedBy': auth?.user?.name, 'therapistId': auth?.user?.id,
          'packageID': dummy1[ 0 ].meta.data._id, 'PackageName': dummy1[ 0 ].meta.data.name, momentDate: moment().format(),
          'duration': dummy1[ 0 ].meta.data.duration, 'price': ipdMoney, 'PackageDesc': dummy1[ 0 ].meta.data.desc,
          'totalAmount': ipdMoney, 'amountAfterDiscount': ipdMoney, "current": ipdMoney, 'status': 'IPD Payment', due: 0,
          "packageDate": packageDate
        } ];
        const res = await axios.post( `${server}/api/medi/patient/ipd-payment/${selected}`, { ipdMoney, arrayAdvance } );
        if ( res.data.success )
        {
          toast.success( res.data.message );
          setVisible( false );
          setTyprOPD( true );
          handleList();
          setIpdMoney( 0 );
        }
      }
      else
      {
        toast.error( "Please Enter Amount" );
      }
    } catch ( error )
    {
      toast.error( "something went wrong on IPD Payment" );
    }
  };

  const handleDate = async ( value ) =>
  {
    // console.log( new Date( value[ 0 ] ).toISOString() );
    let date = [];

    if ( value?.length > 0 )
    {
      Promise.all(
        value.map( item => ( date.push( new Date( item ).toISOString().slice( 0, 10 ) ) ) )
      );
      let newDate = new Date( date[ 1 ] );
      newDate.setDate( newDate.getDate() + 1 );
      date[ 1 ] = newDate.toJSON().slice( 0, 10 );
      let newDate0 = new Date( date[ 0 ] );
      newDate0.setDate( newDate0.getDate() + 1 );
      date[ 0 ] = newDate0.toJSON().slice( 0, 10 );
    }
    else
    {
      date = '';
    }
    setDateRange( date );
  };
  // console.log( dateRange );

  const handleRefund = async () =>
  {
    try
    {
      let amountAfterRefund = amountDiscounted;
      let advan = advance - Number( refund );
      const dummy1 = packageArray;
      const dueAfterRefund = amountDiscounted - advan;

      const arrayAdvance = [ {
        'id': uid( 16 ), 'date': new Date(), 'addedBy': auth?.user?.name, 'therapistId': auth?.user?.id, 'momentDate': moment().format(),
        'status': 'refund', 'refundAmount': Number( refund ), 'refundText': refundText,
        'advAmountAfterRefund': advan, 'discount': discount, 'totalAmount': finalAmount,
        'packageID': dummy1[ 0 ].meta.data._id, 'PackageName': dummy1[ 0 ].meta.data.name,
        'duration': dummy1[ 0 ].meta.data.duration, 'PackageDesc': dummy1[ 0 ].meta.data.desc, 'due': amountDiscounted - advan,
        "packageDate": packageDate
      } ];

      const { data } = await axios.post( `${server}/api/medi/patient/refund/${selected}`, {
        refund, refundText, patientName, arrayAdvance, amountAfterRefund, advan, dueAfterRefund
      } );
      if ( data.success )
      {
        toast.success( data.message );
        handleList();
        setVisible( false );
        setRefund( 0 );
        setDue( 0 );
        setDuration( 0 );
        sertRefundText( '' );
        setAmountDiscounted( 0 );
        setPatientName( '' );

      } else
      {
        toast.error( data.message );
      }
    } catch ( error )
    {
      console.log( error );
      toast.error( "Something went wrong while refund" );
    }
  };

  const handlerefundValue = ( history ) =>
  {
    let dummy = 0;
    Object.values( history ).map( item => ( () =>
    {
      if ( item[ 0 ].status === 'refund' )
      {
        return (
          dummy += item[ 0 ].refundAmount
        );
      }
    } )() );
    setRefundRaw( dummy );
  };

  const handleSubmitCallSchdl = async () =>
  {
    try
    {
      const res = await axios.post( `${server}/api/medi/vcall/create-room`, { value, selected, timezone, emails, videoQuality } );
      if ( res.data.success )
      {
        setVisible( false );
        toast.success( "Call Scheduled" );
        setValue( "" );
        handleList();
        setSelected( "" );
        setTimezone( "" );
        setOk( false );
      } else
      {

        toast.error( res.data.message );
        setOk( false );
      }
    } catch ( error )
    {
      console.log( error );
      toast.error( error );
    }
  };

  const handleDetailsChange = async ( name, email, phone ) =>
  {
    try
    {
      const { data } = await axios.post( `${server}/api/medi/patient/details-change/${selected}`, { edit, staticEmail } );
      if ( data.success )
      {
        toast.success( data.message );
        setName( "" );
        setEmail( "" );
        setStaticEmail( "" );
        setPhone( "" );
        setEdit( "" );
        setVisible( false );
        handleList();
      }
      else
      {
        toast.error( data.message );
      }
    } catch ( error )
    {
      toast.error( error );
    }
  };

  const handleTrial = async () =>
  {
    const { data } = await axios.post( `${server}/api/medi/vcall/trial-session/${selected}`, { selectedTherapist, therapistName: therp.label, therapistEmail: therp.email, value, emails, patientDetails, videoQuality } );
    if ( data.success )
    {
      toast.success( "Trial Call Scheduled" );
      setVisible( false );
      setValue( "" );
      setSelected( "" );
      setTherp( "" );
      setTherapist( "" );
      setSelectedTerapist( "" );
      setEmails( "" );
      setOk( false );
      handleList();
    } else
    {
      console.log( '22222' );
      toast.error( data.message );
      setOk( false );
    }
  };

  const feedBack = async () =>
  {
    try
    {
      let id = '';
      if ( mailId !== undefined && mailId?.length > 0 )
      {
        id = mailId?.split( '/' );
        id = id[ id.length - 1 ];
      } else
      {
        id = uniqid();
      }
      const res = await axios.post( `${server}/api/medi/patient/feedback/${selected}`, { id } );
      if ( res.status === 200 )
      {
        toast.success( res.data.message );
        setPackageName( "" );
        setVisible( false );
        setSelected( "" );
        setMailId( "" );
        handleList();
      }
    } catch ( error )
    {
      toast.error( error );
    }
  };


  useEffect( () =>
  {
    const logNetworkInfo = () =>
    {
      if ( navigator.connection )
      {
        console.log( 'Effective Connection Type:', navigator.connection.effectiveType );
        console.log( 'Downlink Speed:', navigator.connection.downlink, 'Mbps' );
        console.log( 'Round-Trip Time:', navigator.connection.rtt, 'ms' );
        console.log( 'Save Data:', navigator.connection.saveData );
      } else
      {
        console.log( 'Network Information API is not supported by this browser.' );
      }
    };

    logNetworkInfo();

    navigator.connection?.addEventListener( 'change', logNetworkInfo );

    return () =>
    {
      navigator.connection?.removeEventListener( 'change', logNetworkInfo );
    };
  }, [] );


  return (
    <Layout>
      {/* filters */}
      <div className='row'>
        <div className='col-xl-2'>

        </div>
        <div className='col-12 col-xl-8'>
          <div className='row'>
            <div className='col-12 col-xl-4'>
              <h2 className='text-transform-uppercase'>
                <FontAwesomeIcon icon={faBed} /> Patient Master
              </h2>
            </div>

            {/* filter */}
            <div className='col-3 col-xl-2 mt-2'>
              <div className=''>
                <small>
                  {/* <label>Type : </label> */}
                </small>
                <Select
                  placeholder={"Please Select type"}
                  defaultValue={auth?.user?.roleName === 'Admin' ? options[ 0 ].value : optionsForTherapist[ 0 ].value}
                  className='form-control p-0'
                  options={auth?.user?.roleName === 'Admin' ? options : optionsForTherapist}
                  onChange={setFilter}
                  value={options.value}
                />
              </div>
            </div>

            <div className='col-3 col-xl-2 mt-2'>
              {hospitals.length > 0
                ? ( <Select
                  // defaultValue={hospitals.length ? ( hospitals[ hospitalIndex ].value ) : ( "" )}
                  placeholder={"Select Hospital"}
                  className='form-control p-0'
                  options={hospitals}
                  onChange={setSelectedHsopital}
                  showSearch
                  allowClear
                // value={Object.entries( hospitals[ 0 ] ).value}
                /> )
                : ( <></> )}
            </div>

            <div className='col-3 col-xl-2 mt-2'>
              <DateRangePicker
                hoverRange={'month'}
                oneTap
                showOneCalendar
                onChange={handleDate}
                shouldDisableDate={date => isAfter( date, new Date() )}
                placeholder={"Filter Date"}
              />
            </div>
            {/* hospital filter */}
            <div className='col-3 col-xl-2 mt-2'>
              <div className='input-group'>
                <input type='text' placeholder='Search by name' minLength={3} className='form-control'
                  onKeyUp={( e ) => handleKeyUp( e )}></input>
                {/* <button className="btn btn-dark btn-sm" id="btnNavbarSearch" type="button"><FontAwesomeIcon icon={faSearch} /></button> */}
              </div>
            </div>

          </div>
          <hr />
        </div>
      </div>

      {/* patient list */}
      <div className='row'>
        <div className='col-xl-2'>

        </div>
        <div className='col-12 col-xl-8'>

          <Card className='bg-light'>
            <Card.Body className='overflow-auto' style={( () =>
            {
              if ( isDesktopOrLaptop )
              {
                return ( { maxHeight: '480px' } );
              }
              if ( isTabletOrMobile )
              {
                return ( { maxHeight: '700px' } );
              }
            } )()}>

              {/* <Card.Body className='overflow-auto' style={{ maxHeight: '480px' }}> */}
              {/* indivisual cards */}
              {( () =>
              {
                if ( pageCount === 0 )
                {
                  return (
                    <div className='row'>
                      <div className='col-12 text-center'>
                        {patients}
                      </div>
                    </div>
                  );
                }
                else
                {
                  return (
                    <div>
                      {
                        Object.values( patients ).map( ( k ) => (
                          <div className='row mt-1' key={k._id}>
                            <div className='col-12 col-xl-12'>
                              {/* card start */}
                              <Card key={k._id} >
                                {/* card header */}
                                <Card.Header className={( () =>
                                {
                                  if ( ( k.advanceAmount < k.finalAmount ) && ( k.sessionList.length < k.duration ) )
                                  {
                                    return (
                                      'alert alert-danger'
                                    );
                                  }
                                  if ( ( k.advanceAmount === k.finalAmount ) && ( k.sessionList.length === k.duration ) )
                                  {
                                    return (
                                      'alert alert-success'
                                    );
                                  }
                                  if ( ( k.advanceAmount === k.finalAmount ) && ( k.sessionList.length < k.duration ) )
                                  {
                                    return (
                                      'alert alert-info'
                                    );
                                  }
                                  if ( ( k.advanceAmount < k.amountAfterDiscount ) && ( k.sessionDone === k.duration ) && ( k.type === 'opd' ) )
                                  {
                                    return (
                                      'alert alert-primary'
                                    );
                                  }
                                  if ( ( k.type === 'ipd' ) && ( k.sessionDone === k.duration ) )
                                  {
                                    return (
                                      'alert alert-success'
                                    );
                                  }
                                } )()}>
                                  <div className='row'>
                                    <div className='col-12'>
                                      {/* card header row */}
                                      <div className='row'>
                                        <div className='col-12 col-xl-4'>
                                          {'# ' + k.UID + " / "}
                                          {k.MrNo.length > 1 ? ( <Link to='#' onClick={() =>
                                          {
                                            setMrNo( k.MrNo );
                                            setType( 'MrNo' );
                                            setVisible( true );
                                          }}>
                                            {k.MrNo[ k.MrNo.length - 1 ]}
                                          </Link> ) : ( k.MrNo )}

                                        </div>
                                        <div className='col-12 col-xl-4'>
                                          {new Date( k.createdAt ).toDateString()}
                                        </div>
                                        <div className='col-12 col-xl-4'>
                                          <span className='alert alert-info py-1 small float-end mb-0 '>
                                            {k.status}
                                          </span>
                                          {k.therapistArray ? (
                                            <span className='alert alert-info py-1 small float-end mb-0'>
                                              {k.therapistArray ? ( "Assigned : " + JSON.parse( k.therapistArray )[ 0 ]?.label ) : ( <></> )}
                                            </span>
                                          ) : ( <></> )}
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-12 col-xl-4'>

                                          {" Status : " + ( () =>
                                          {
                                            if ( ( k.advanceAmount < k.amountAfterDiscount && k.type === 'opd' ) && ( k.sessionDone < k.duration ) )
                                            {
                                              return (
                                                'Payment & Sessions Pending'
                                              );
                                            }
                                            if ( ( k.advanceAmount === k.amountAfterDiscount ) && ( k.sessionDone === k.duration ) )
                                            {
                                              return (
                                                'Completed'
                                              );
                                            }
                                            if ( ( ( k.advanceAmount === k.amountAfterDiscount ) && ( k.type === 'opd' ) ) && ( k.sessionDone < k.duration ) )
                                            {
                                              return (
                                                'Sessions Pending'
                                              );
                                            }
                                            if ( ( ( k.ipdPayment === 'due' ) && ( k.type === 'ipd' ) ) && ( k.sessionDone < k.duration ) )
                                            {
                                              return (
                                                'Sessions & Payment Pending'
                                              );
                                            }
                                            if ( ( ( k.ipdPayment === 'done' ) && ( k.type === 'ipd' ) ) && ( k.sessionDone < k.duration ) )
                                            {
                                              return (
                                                'Sessions Pending'
                                              );
                                            }
                                            if ( ( k.advanceAmount < k.amountAfterDiscount ) && ( k.sessionDone === k.duration ) && ( k.type === 'opd' ) )
                                            {
                                              return (
                                                'Payment Pending'
                                              );
                                            }
                                            if ( ( k.type === 'ipd' ) && ( k.ipdPayment === 'done' ) && ( k.sessionDone === k.duration ) )
                                            {
                                              return (
                                                'Completed'
                                              );
                                            }
                                            if ( ( k.type === 'ipd' ) && ( k.ipdPayment === 'due' ) && ( k.sessionDone === k.duration ) )
                                            {
                                              return (
                                                'Payment Pending'
                                              );
                                            }
                                            else
                                            {
                                              return (
                                                "New"
                                              );
                                            }
                                          } )()}

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Card.Header>
                                {/* card body */}
                                <Card.Body >
                                  <div className='row'>
                                    <div className='col-12'>
                                      {/** 1st row */}
                                      <div className='row mt-1'>
                                        <div className='col-12 col-xl-6' >
                                          <FontAwesomeIcon icon={faBed} />{" "}
                                          Patient Name :
                                          <b> <Link onClick={() =>
                                          {
                                            setType( 'patient-details' );
                                            setPatientDetails( [ k.email, k.phone, k.city, k.country, k.name ] );
                                            setEmail( k.email );
                                            setPhone( k.phone );
                                            setName( k.name );
                                            setStaticEmail( k.email );
                                            setVisible( true );
                                            setSelected( k._id );
                                          }}>
                                            {" " + k.name}
                                          </Link></b>
                                        </div>
                                        {/* patient hospital*/}
                                        {k.hospitalName !== undefined
                                          ? ( <div className='col-12 col-xl-6'>
                                            <FontAwesomeIcon icon={faHospitalAlt} />{" "}
                                            Hospital :
                                            <b>
                                              {" " + k.hospitalName}
                                            </b>
                                          </div> )
                                          : ( <></> )}

                                      </div>
                                      {/** 2nd  row */}
                                      <div className='row mt-1'>
                                        <div className='col-12 col-xl-6' >
                                          <FontAwesomeIcon icon={faMobile} />{" "}
                                          Mobile No :
                                          <b>
                                            {" " + k.phone}
                                          </b>
                                        </div>
                                        {/*patient package*/}
                                        {k.assignPackageArray
                                          ? (
                                            <div className='col-12 col-xl-6' >
                                              <FontAwesomeIcon icon={faHandHoldingHeart} />{" "}
                                              Package :
                                              <b>
                                                <Link to='#' onClick={() =>
                                                {
                                                  setSelected( k._id );
                                                  setPatientHospital( k.hospital );
                                                  setCurrentPackage( k.assignPackageId );
                                                  if ( k.assignPackageArray )
                                                  {
                                                    setSelectedPAckage( JSON.parse( k.assignPackageArray )[ 0 ].value );
                                                    setModalFlag( false );
                                                  }
                                                  if ( k.duration )
                                                  {
                                                    setFlagDummy( true );
                                                    setDuration( k.duration );
                                                    setPrice( k.price );
                                                    setFinalAmount( k.finalAmount );
                                                    setStartDate( new Date( k.startDate ) );
                                                    setEndDate( new Date( k.endDate ) );
                                                    setFlag_dummy( true );
                                                    setSessionDate( new Date( k.sessionDate ) );
                                                    setAdvance( k.advanceAmount );
                                                    setDiscount( k.discount );
                                                    setAmountDiscounted( k.amountAfterDiscount );
                                                    setRefund( handlerefundValue( k.paymentHistory ) );
                                                    setDueRaw( k.due );
                                                  }
                                                  else
                                                  {
                                                    setFlag_dummy( false );
                                                    setEndDate( '' );
                                                  }
                                                  if ( k.type === 'ipd' )
                                                  {
                                                    setPatientType( false );
                                                  }
                                                  sePatentType( k.type );
                                                  setCurrency( k?.currency || "INR" );
                                                  setShowSubmit( false );
                                                  setType( "assign-package" );
                                                  setVisible( true );
                                                }}>
                                                  {" " + JSON.parse( k.assignPackageArray )[ 0 ]?.label}
                                                </Link>
                                              </b>
                                            </div>
                                          )
                                          : ( <></> )}

                                      </div>
                                      {/** 3rd  row */}
                                      <div className='row mt-1'>
                                        <div className='col-12 col-xl-6'>
                                          <FontAwesomeIcon icon={faHospitalUser} />{" "}
                                          Patient Type :
                                          <b>
                                            {" " + k.type}
                                          </b>
                                        </div>
                                        {k.diagnosticType ? ( <div className='col-12 col-xl-6'>
                                          <FontAwesomeIcon icon={faPersonDotsFromLine} />{" "}
                                          Diagnose :
                                          {/* {JSON.stringify( k.diagnosticChildList )} */}
                                          <Link key={k._id} onClick={() =>
                                          {
                                            setDiagnostictype( k.diagnosticType );
                                            setDiagnosticData( k.diagnosticChildList );
                                            setVisible( true );
                                            setType( 'diagnostic' );
                                          }}>
                                            <b>
                                              {" " + k.diagnosticType}
                                            </b>
                                          </Link>
                                        </div> ) : ( <></> )}

                                      </div>
                                      {/** 4th row */}
                                      <div className='row mt-1'>
                                        <div className='col-12 col-xl-6' >
                                          <FontAwesomeIcon icon={faUmbrella} />{" "}
                                          Patient Category :
                                          <b>
                                            {" " + k.category}
                                          </b>
                                        </div>
                                        {k.roomDetails.length > 0 ? ( <div className='col-12 col-xl-6' >
                                          <FontAwesomeIcon icon={faVideo} />{" "}
                                          Call Details :
                                          <Link key={k._id} onClick={() =>
                                          {
                                            setRoomDetails( k.roomDetails );
                                            setSelected( k._id );
                                            setVisible( true );
                                            setType( 'roomDetails' );
                                          }}>
                                            <b>
                                              {"Video Call Details"}
                                            </b>
                                          </Link>
                                        </div> ) : ( <></> )}
                                      </div>
                                      {/* 5th row */}
                                      <div className='row mt-1'>
                                        <div className='col-12 col-xl-6' >
                                          <FontAwesomeIcon icon={faThumbsUp} />{" "}
                                          Session Done :
                                          <b>
                                            {" " + k.sessionDone + "/" + k.duration}
                                          </b>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* patient condition */}
                                  <div className='row mt-1 border rounded  p-3'>
                                    <div className='col-12 '>
                                      <b><FontAwesomeIcon icon={faFileInvoice} />   Condition:</b>
                                      <p>
                                        {k.condition}
                                      </p>
                                    </div>
                                  </div>
                                </Card.Body>
                                {/* card footer */}
                                <Card.Footer className={( () =>
                                {
                                  if ( ( k.advanceAmount < k.amountAfterDiscount ) && ( k.sessionList.length < k.duration ) )
                                  {
                                    return (
                                      'alert alert-danger'
                                    );
                                  }
                                  if ( ( k.advanceAmount === k.amountAfterDiscount ) && ( k.sessionList.length === k.duration ) )
                                  {
                                    return (
                                      'alert alert-success'
                                    );
                                  }
                                  if ( ( k.advanceAmount === k.amountAfterDiscount ) && ( k.sessionList.length < k.duration ) )
                                  {
                                    return (
                                      'alert alert-info'
                                    );
                                  }
                                  if ( ( k.advanceAmount === k.amountAfterDiscount ) && ( k.sessionDone < k.duration ) )
                                  {
                                    return (
                                      'alert alert-danger'
                                    );
                                  }
                                  if ( ( k.advanceAmount < k.amountAfterDiscount ) && ( k.sessionDone === k.duration ) && ( k.type === 'opd' ) )
                                  {
                                    return (
                                      'alert alert-primary'
                                    );
                                  }
                                  if ( ( k.type === 'ipd' ) && ( k.sessionDone === k.duration ) )
                                  {
                                    return (
                                      'alert alert-success'
                                    );
                                  }
                                } )()}>
                                  {/* grid start */}
                                  <div className='grid grid-cols-4 justify-items-center md:grid-cols-6 gap-2'>
                                    {/* assign therapist */}
                                    <div className='w-fit'>
                                      <button
                                        className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                        onClick={() =>
                                        {
                                          setVisible( true );
                                          setType( 'assign-therapist' );
                                          setSelected( k._id );
                                          setSelectedHospitalNew( k.hospital );
                                          if ( k.therapistArray )
                                          {
                                            setTherapistArray( JSON.parse( k.therapistArray ) );
                                            setSelectedTerapist( JSON.parse( k.therapistArray )[ 0 ].value );
                                          }
                                        }}>
                                        {k.therapistArray ? ( "Reassign Therapist" ) : ( "Assign Therapist" )}
                                      </button>
                                    </div>
                                    {/* trial session */}
                                    {filter === 'new' && k.trial.length === 0
                                      ? (
                                        <div className='w-fit text-center'>
                                          {/* <div className='col-4 col-sm-3 col-xl-2'> */}
                                          <button
                                            className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                            onClick={() =>
                                            {
                                              setSelected( k._id );
                                              setName( k.name );
                                              setSelectedHospitalNew( k.hospital );
                                              setPatientDetails( k );
                                              setType( "trial-session" );
                                              setVisible( true );
                                            }}
                                          >
                                            Trial Session
                                          </button>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    {/* assign package */}
                                    {filter === 'self-assign' && !k.assignPackageArray
                                      ? (
                                        <div className='w-fit text-center'>
                                          {/* <div className='col-4 col-sm-3 col-xl-2'> */}
                                          <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={() =>
                                          {
                                            setSelected( k._id );
                                            setPatientHospital( k.hospital );
                                            setSelectHospitalPackage( k.hospital );
                                            // console.log( k.assignPackageArray );
                                            if ( k.assignPackageArray )
                                            {
                                              setSelectedPAckage( JSON.parse( k.assignPackageArray )[ 0 ].value );
                                              setModalFlag( false );
                                            }
                                            if ( k.type === 'ipd' )
                                            {
                                              setPatientType( false );
                                              setPrice( 0 );
                                              setFinalAmount( 0 );
                                              // setStartDate( new Date( k.startDate ) );
                                              // setEndDate( new Date( k.endDate ) );
                                              setAdvance( 0 );
                                              // setFlag_dummy( true );
                                              // setDuration( k.duration );
                                              // setSessionDate( new Date( k.sessionDate ) );
                                              setTyprOPD( false );
                                              setPatientType( false );
                                              // console.log( 'ipd' );

                                            }
                                            if ( k.type === 'opd' )
                                            {
                                              // console.log( 'opd' );
                                              // setDuration( k.duration );
                                              // setPrice( k.price );
                                              // setFinalAmount( k.finalAmount );
                                              // setStartDate( new Date( k.startDate ) );
                                              // setEndDate( new Date( k.endDate ) );
                                              // setFlag_dummy( true );
                                              // setSessionDate( new Date( k.sessionDate ) );
                                              // setAdvance( k.advanceAmount );
                                              setTyprOPD( true );
                                              setPatientType( true );
                                            }
                                            if ( !k.assignPackageArray )
                                            {
                                              setFlag_dummy( false );
                                              setEndDate( '' );
                                            }
                                            sePatentType( k.type );
                                            setCurrency( k?.currency || "INR" );
                                            setType( "assign-package" );
                                            setVisible( true );
                                            setReassignPackageFlag( false );
                                          }}>
                                            Assign Package
                                          </button>
                                        </div> )
                                      : ( <></> )}
                                    {/* {setAdvance(k.advanceAmount)} */}
                                    {k.amountAfterDiscount > k.advanceAmount && k.type === 'opd'
                                      ? (
                                        <div className='w-fit text-center'>
                                          {/* <div className='col-4 col-sm-3 col-xl-2'> */}
                                          <div className='border rounded float-end ' >
                                            <button
                                              className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                              onClick={() =>
                                              {
                                                setPackageDate( k.packageList.slice( -1 ).flat()[ 0 ][ 'date' ] );
                                                setSelected( k._id );
                                                setAdvance( k.advanceAmount );
                                                setType( 'add-money' );
                                                setVisible( true );
                                                setTotalAmount( k.finalAmount );
                                                setFinalAmount( k.finalAmount );
                                                setDiscount( k.discount );
                                                setAmountDiscounted( k.amountAfterDiscount );
                                                setPaidPrevious( k.paidPrevious );
                                                setDue( k.due );
                                                setPackageId( k.assignPackageId );
                                                setPackageName( () =>
                                                {
                                                  setPackageArray( JSON.parse( k.assignPackageArray ) );
                                                } );
                                              }}
                                            >
                                              Add Payment
                                            </button>
                                          </div>
                                        </div>
                                      )
                                      : ( <></> )}
                                    {/* IPD Payment */}
                                    {k.type === 'ipd' && k.ipdPayment !== 'done' && filter !== 'new' && ( k.packageList.length > 0 )
                                      ? (
                                        <div className='w-fit text-center'>
                                          {/* <div className='col-4 col-sm-3 col-xl-2'> */}
                                          <div className='border rounded float-end btn-sm'>
                                            <button
                                              className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                              onClick={() =>
                                              {
                                                setPackageDate( k.packageList.slice( -1 ).flat()[ 0 ][ 'date' ] );
                                                setSelected( k._id );
                                                setType( 'add-money-ipd' );
                                                setVisible( true );
                                                setPackageName( () =>
                                                {
                                                  setPackageArray( JSON.parse( k.assignPackageArray ) );
                                                } );
                                              }}
                                            >
                                              IPD Payment
                                            </button>
                                          </div>
                                        </div> )
                                      : ( <></> )}
                                    {/* view session */}
                                    {k.sessionList.length > 0
                                      ? (
                                        // <div className='col-4 col-sm-3 col-xl-2'>
                                        <div className='w-fit text-center'>
                                          {k.sessionList
                                            ? (
                                              <div className='border rounded float-end btn-sm' >
                                                <Tooltip placement="bottomRight" title={"View Sessions"} >
                                                  <button
                                                    className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                                    onClick={() =>
                                                    {
                                                      setType( 'view' );
                                                      setVisible( true );
                                                      setSessionList( k.sessionList );
                                                      setSelected( k._id );
                                                      setPatientCondition( k.condition );
                                                    }}

                                                  >
                                                    View Sessions
                                                  </button>
                                                </Tooltip>
                                              </div>
                                            )
                                            : ( <></> )}
                                        </div> )
                                      : ( <></> )}
                                    {/* payment history */}
                                    {k.paymentHistory.length > 0
                                      ? (
                                        <div className='w-fit text-center'>
                                          {/* <div className='col-4 col-sm-3 col-xl-2'> */}
                                          <div className='border rounded float-end'>
                                            <Tooltip placement='bottomRight' title={"Payment History"}>
                                              <button
                                                className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                                onClick={() =>
                                                {
                                                  setType( 'payment-history' );
                                                  setVisible( true );
                                                  setPaymentHistory( k.paymentHistory );
                                                  setSelectedPatient( k._id );
                                                  sePatentType( k.type );
                                                }}
                                              >
                                                Payment History
                                              </button>
                                            </Tooltip>
                                          </div>
                                        </div> )
                                      : ( <></> )}
                                    {/* reassign package */}
                                    {k.duration === k.sessionDone && k.due === 0 && k.type === 'ipd'
                                      ? (
                                        <div className='w-fit text-center'>
                                          {/* <div className='col-4 col-sm-3 col-xl-2'> */}
                                          <div className='border rounded float-end'>
                                            <Tooltip placement='bottomRight' title={"Reassign Package"}>
                                              <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={() =>
                                              {
                                                setSelected( k._id );
                                                setPatientHospital( k.hospital );
                                                setSelectHospitalPackage( k.hospital );
                                                if ( k.assignPackageArray )
                                                {
                                                  setSelectedPAckage( '' );
                                                  setModalFlag( false );
                                                }
                                                if ( k.type === 'ipd' && k.duration )
                                                {
                                                  setPatientType( false );
                                                  setPrice( 0 );
                                                  setFinalAmount( 0 );
                                                  setStartDate( new Date( k.startDate ) );
                                                  setEndDate( new Date( k.endDate ) );
                                                  setAdvance( 0 );
                                                  setFlag_dummy( false );
                                                  setDuration( k.duration );
                                                  setSessionDate( new Date( k.sessionDate ) );
                                                  setTyprOPD( false );
                                                }
                                                if ( k.duration && k.type === 'opd' )
                                                {
                                                  setDuration( k.duration );
                                                  setPrice( k.price );
                                                  setFinalAmount( k.finalAmount );
                                                  setStartDate( new Date( k.startDate ) );
                                                  setEndDate( new Date( k.endDate ) );
                                                  setFlag_dummy( false );
                                                  setSessionDate( new Date( k.sessionDate ) );
                                                  // setAdvance( k.advanceAmount );
                                                  setTyprOPD( true );
                                                }
                                                else
                                                {
                                                  setFlag_dummy( false );
                                                  setEndDate( '' );
                                                }
                                                setMrNo( k.MrNo[ k.MrNo.length - 1 ] );
                                                setMrFlag( true );
                                                setPatientTypeReassign( k.type );
                                                setCurrency( k?.currency || "INR" );
                                                sePatentType( k.type );
                                                setType( "assign-package" );
                                                setVisible( true );
                                                setReassignPackageFlag( true );
                                              }}>
                                                Reassign Package
                                              </button>
                                            </Tooltip>
                                          </div>
                                        </div> )
                                      : ( <></> )}
                                    {/* reassign package */}
                                    {k.duration === k.sessionDone && k.due === 0 && k.type === 'opd'
                                      ? (
                                        <div className='w-fit text-center'>
                                          {/* <div className='col-4 col-sm-3 col-xl-2'> */}
                                          <div className='border rounded float-end'>
                                            <Tooltip placement='bottomRight' title={"Reassign Package"}>
                                              <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={() =>
                                              {
                                                setSelected( k._id );
                                                setPatientHospital( k.hospital );
                                                setSelectHospitalPackage( k.hospital );
                                                if ( k.assignPackageArray )
                                                {
                                                  setSelectedPAckage( '' );
                                                  setModalFlag( false );
                                                }
                                                if ( k.type === 'ipd' && k.duration )
                                                {
                                                  setPatientType( false );
                                                  setPrice( 0 );
                                                  setFinalAmount( 0 );
                                                  setStartDate( new Date( k.startDate ) );
                                                  setEndDate( new Date( k.endDate ) );
                                                  setAdvance( 0 );
                                                  setFlag_dummy( false );
                                                  setDuration( k.duration );
                                                  setSessionDate( new Date( k.sessionDate ) );
                                                  setTyprOPD( false );
                                                }
                                                if ( k.duration && k.type === 'opd' )
                                                {
                                                  setDuration( k.duration );
                                                  setPrice( k.price );
                                                  setFinalAmount( k.finalAmount );
                                                  setStartDate( new Date( k.startDate ) );
                                                  setEndDate( new Date( k.endDate ) );
                                                  setFlag_dummy( false );
                                                  setSessionDate( new Date( k.sessionDate ) );
                                                  // setAdvance( k.advanceAmount );
                                                  setTyprOPD( true );
                                                }
                                                else
                                                {
                                                  setFlag_dummy( false );
                                                  setEndDate( '' );
                                                }
                                                setMrNo( k.MrNo[ k.MrNo.length - 1 ] );
                                                setMrFlag( true );
                                                setPatientTypeReassign( k.type );
                                                sePatentType( k.type );
                                                setCurrency( k?.currency || "INR" );
                                                setType( "assign-package" );
                                                setVisible( true );
                                                setReassignPackageFlag( true );
                                              }}>
                                                Reassign Package
                                              </button>
                                            </Tooltip>
                                          </div>
                                        </div> )
                                      : ( <></> )}
                                    {/* package detail*/}
                                    {k.assignPackageId
                                      ? (
                                        <div className='w-fit text-center'>
                                          {/* <div className='col-4 col-sm-3 col-xl-2'> */}
                                          <div className='border rounded float-end'>
                                            <Tooltip placement='bottomRight' title={"View Package Details"}>
                                              <button
                                                className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                                onClick={() =>
                                                {
                                                  setSelected( k._id );
                                                  setType( 'package-detail' );
                                                  setVisible( true );
                                                  setPackageData( k.packageList );
                                                }}
                                              >
                                                View Package
                                              </button>
                                            </Tooltip>
                                          </div>
                                        </div> )
                                      : ( <></> )}
                                    {/* refund amount */}
                                    {k.assignPackageId && k.type === 'opd' && k.advanceAmount > 0
                                      ? ( <>
                                        <div className='w-fit text-center'>
                                          {/* <div className='col-4 col-sm-3 col-xl-2'> */}
                                          <div className='border rounded float-end'>
                                            <Tooltip placement='bottomRight' title={"Refund Amount"}>
                                              <button
                                                className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                                onClick={() =>
                                                {
                                                  setPackageDate( k.packageList.slice( -1 ).flat()[ 0 ][ 'date' ] );
                                                  setType( 'refund' );
                                                  setVisible( true );
                                                  setSelected( k._id );
                                                  setPatientName( k.name );
                                                  if ( k.duration )
                                                  {
                                                    setDuration( k.duration );
                                                    setPrice( k.price );
                                                    setFinalAmount( k.finalAmount );
                                                    setStartDate( new Date( k.startDate ) );
                                                    setEndDate( new Date( k.endDate ) );
                                                    setFlag_dummy( true );
                                                    setSessionDate( new Date( k.sessionDate ) );
                                                    setAdvance( k.advanceAmount );
                                                    setDiscount( k.discount );
                                                    setAmountDiscounted( k.amountAfterDiscount );
                                                    setDue( k.due );
                                                    setPackageName( () =>
                                                    {
                                                      setPackageArray( JSON.parse( k.assignPackageArray ) );
                                                    } );
                                                  }
                                                }}
                                              >
                                                Refund Amount
                                              </button>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </> )
                                      : ( <></> )}
                                    {/* Schedule a call */}
                                    {k.sessionDone < k.duration ? (
                                      <div className='w-fit text-center'>
                                        {/* <div className='col-4 col-sm-3 col-xl-2'> */}
                                        <div className='border rounded'>
                                          <Tooltip placement='bottomRight' title={"Schedule Video Call"}>
                                            <button
                                              className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                              onClick={() =>
                                              {
                                                setType( "schedule" );
                                                setSelected( k._id );
                                                setPatientName( k.name );
                                                setTimezone( k );
                                                setVisible( true );
                                              }}
                                            >
                                              {/* {JSON.stringify( k )} */}
                                              Schedule Session
                                            </button>
                                          </Tooltip>
                                        </div>
                                      </div> ) : ( <></> )}
                                    {/* feedback */}
                                    {Object.values( auth?.user?.capabilities ).includes( 'feedback' )
                                      && k.sessionDone <= k.duration
                                      && k.sessionDone >= 1
                                      ? (
                                        <div className='w-fit text-center'>
                                          {/* <div className='col-4 col-sm-3 col-xl-2 mt-2'> */}
                                          <div className='border rounded'>
                                            <Tooltip placement='bottomRight' title={"Patient Feedback"}>
                                              <button
                                                className={
                                                  k?.feedBackMail === 'submitted'
                                                    ? "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                                    : k?.feedBackMail === 'sent'
                                                      ? "text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium text-sm px-2 py-1 md:px-3 md:py-2 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                                                      : "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                                }
                                                onClick={() =>
                                                {
                                                  setTimezone( k );
                                                  setVisible( true );
                                                  setPatientName( k );
                                                  setSelected( k._id );
                                                  setType( "Feedback" );
                                                  setMailId( k.sentMailID );

                                                  if ( k?.feedBackMail === 'submitted' )
                                                  {
                                                    setFeedType( "submitted" );
                                                  } else if ( k?.feedBackMail === 'sent' )
                                                  {
                                                    setFeedType( "sent" );
                                                  } else
                                                  {
                                                    setFeedType( "submitted" );
                                                  }

                                                }}
                                              >
                                                Patient Feedback
                                              </button>
                                            </Tooltip>
                                          </div>
                                        </div> ) : ( <></> )}
                                    {/* feedback list  */}
                                    {k?.feedBack.length > 0
                                      ? ( <>
                                        <div className='w-fit text-center'>
                                          {/* <div className='col-4 col-sm-3 col-xl-2 mt-2'> */}
                                          <div className='border rounded'>
                                            <Tooltip placement='bottomRight' title={"View Patient Feedback List"}>
                                              <button
                                                type='button'
                                                className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 md:px-3 md:py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                                onClick={() =>
                                                {
                                                  setType( 'feedList' );
                                                  setFeedBackList( k.feedBack );
                                                  setVisible( true );
                                                }}
                                              >
                                                View FeedBack
                                              </button>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </> )
                                      : ( <></> )}


                                  </div>
                                </Card.Footer>
                              </Card>
                            </div>
                          </div>
                        ) )
                      }
                    </div>
                  );
                }
              } )()}
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* pagination */}
      <div className='float-end mt-2' >
        {pageCount > 1 && (
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              showFirstButton
              showLastButton
              color='primary'
              onChange={( event, value ) => setPage( value )}
            />
          </Stack>
          // <MyPagination
          //   total={pageCount}
          //   current={page}
          //   onChangePage={handlePageChange}
          // />
        )}
      </div>

      {/* modal */}
      <Modal open={visible} onCancel={() =>
      {
        setVisible( false );
        setDuration( '' );
        setPrice( '' );
        setFinalAmount( '' );
        setSelectedPAckage( '' );
        setSelectedTerapist( '' );
        setPaymentHistory( '' );
        setPatientType( true );
        setSelectedPatient( '' );
        // setAdvance( '' );
        setTotalAmount( '' );
        setRoomDetails( "" );
        setSelected( "" );
        SetAdvanceNew( '' );
        setPaymentId( '' );
        setFlag_dummy( false );
        setFlagDummy( false );
        setAdvance( 0 );
        setSelectedSession( '' );
        setSelected( '' );
        setSelected( "" );
        setName( "" );
        setMailId( "" );
        setSelectedHospitalNew( "" );
        setPatientDetails( "" );
        setType( "" );
        setSessionList( '' );
        setTyprOPD( true );
        setPatientTypeReassign( "" );
        setMrNo( "" );
        setMrFlag( false );
        setShowSubmit( true );
        setDocumentID( '' );
        setDiscount( 0 );
        setPackageDate( "" );
        setReassignPackageFlag( false );
      }} destroyOnClose footer={null}
        // width={1000}
        width={( () =>
        {
          if ( type === 'invoice' )
          {
            return ( 1000 );
          }
          else
          {
            return ( 600 );
          }
        } )()}
      >
        {( () =>
        {
          if ( type === 'assign-therapist' )
          {
            return (
              <AssignTherapist
                therapist={therapistOption}
                setSelectedTerapist={setSelectedTerapist}
                selectedTherapist={selectedTherapist}
                therapistArray={therapistArray}
                handleSubmit={handletherapistAssign}
              /> );
          }
          if ( type === "schedule" )
          {
            return (
              <ScheduleCall
                selected={selected}
                patientName={patientName}
                timezone={timezone}
                handleSubmitCallSchdl={handleSubmitCallSchdl}
                setValue={setValue}
                value={value}
                setSelected={setSelected}
                emails={emails}
                setEmails={setEmails}
                focused={focused}
                setFocused={setFocused}
                setOk={setOk}
                ok={ok}
                videoQuality={videoQuality}
                setVideoQuality={setVideoQuality}
              />
            );
          }
          if ( type === 'assign-package' )
          {
            return (
              <AssignPackage
                options={packagesOptions} showSubmit={showSubmit} setMrNo={setMrNo}
                setSelectedPAckage={setSelectedPAckage} MrNo={MrNo} setPatientTypeReassign={setPatientTypeReassign} patientTypeReassign={patientTypeReassign}
                selectedPackage={selectedPackage} mrFlag={mrFlag} currency={currency} setCurrency={setCurrency}
                handleSubmit={handleAssignPatient} dueRaw={dueRaw} setTyprOPD={setTyprOPD}
                setStartDate={setStartDate} setEndDate={setEndDate} typeOPD={typeOPD}
                startDate={startDate} endDate={endDate} refundAmount={refund}
                dates={dates} setDates={setDates} refundRaw={refundRaw} setPatientType={setPatientType}
                setFinalAmount={setFinalAmount} finalAmount={finalAmount}
                duration={duration} setDuration={setDuration}
                price={price} setPrice={setPrice} sePatentType={sePatentType}
                setFlag_dummy={setFlag_dummy} flag_dummy={flag_dummy}
                setModalFlag={setModalFlag} setSessionDate={setSessionDate} sessionDate={sessionDate}
                setAdvance={setAdvance} advance={advance} patientHospital={patientHospital}
                amountDiscounted={amountDiscounted} setAmountDiscounted={setAmountDiscounted}
                discount={discount} setDiscount={setDiscount} currentPackage={currentPackage}
                setAmountAfterDiscountRaw={setAmountAfterDiscountRaw} amountAfterDiscountRaw={amountAfterDiscountRaw}
                type={type} setType={setType} patientType={patientType} setPaidPrevious={setPaidPrevious} PatentType={PatentType}
              />
            );
          }
          if ( type === 'invoice' )
          {
            return (
              <Invoice
                PatentType={PatentType}
                patientDetail={patientDetail}
                paymentId={paymentId}
              />
            );
          }
          if ( type === 'add-money-ipd' )
          {
            return (
              <AddMoreMoneyIPD
                setIpdMoney={setIpdMoney} handleIPDMoneySubmit={handleIPDMoneySubmit}
              />
            );
          }
          if ( type === 'patient-details' )
          {
            return (
              <PatientDetails
                patientDetails={patientDetails}
                selected={selected}
                name={name}
                setName={setName}
                phone={phone}
                setPhone={setPhone}
                email={email} edit={edit} setEdit={setEdit}
                setEmail={setEmail}
                handleDetailsChange={handleDetailsChange}
              />
            );
          }
          if ( type === 'add-money' )
          {
            return (
              <AddMoreMoney
                setAdvance={setAdvance} advance={advance}
                totalAmount={totalAmount} setTotalAmount={setTotalAmount}
                advanceNew={advanceNew} SetAdvanceNew={SetAdvanceNew}
                handleAdvance={handleAdvance} discount={discount}
                amountDiscounted={amountDiscounted} paidPrevious={paidPrevious} due={due}
              />
            );
          }
          if ( type === 'payment-history' )
          {
            return (
              <PaymentHistory
                handleClick={handleClick}
                paymentHistory={paymentHistory}
                selectedPatient={selectedPatient}
              />
            );
          }
          if ( type === 'documents' )
          {
            return (
              <DocumentsView
                selectedSession={selectedSession}

              />
            );
          }
          if ( type === 'view' )
          {
            return (
              <ViewSessionDetails
                sessionList={sessionList}
                documentID={documentID} setDocumentID={setDocumentID}
                patientCondition={patientCondition}
                handleDocumentClick={handleDocumentClick}
              />
            );
          }
          if ( type === 'package-detail' )
          {
            return (
              <PackageDetail
                packageData={packageData}
              />
            );
          }
          if ( type === 'diagnostic' )
          {
            return (
              <DiagnosticViewModal
                diagnosticType={diagnosticType}
                diagnosticData={diagnosticData}
              /> );
          }
          if ( type === 'date' )
          {
            return (
              <MonthPicker
                smonth={smonth} syear={syear} setYear={setYear} setMonth={setMonth}
                setVisible={setVisible}
              />
            );
          }
          if ( type === 'MrNo' )
          {
            return (
              <MrNoPage
                MrNo={MrNo}
              />
            );
          }
          if ( type === 'refund' )
          {
            return (
              <Refund
                amountDiscounted={amountDiscounted} discount={discount}
                advance={advance} duration={duration} handleRefund={handleRefund}
                refund={refund} setRefund={setRefund} sertRefundText={sertRefundText}
              />
            );
          }
          if ( type === 'trial-session' )
          {
            return (
              <TrailSession
                handleTrial={handleTrial}
                selected={selected}
                therapist={therapistOption}
                setTherapist={setTherapist}
                setSelectedTerapist={setSelectedTerapist}
                selectedTherapist={selectedTherapist}
                patientDetails={patientDetails}
                emails={emails}
                setEmails={setEmails}
                setOk={setOk}
                ok={ok}
                setValue={setValue}
                value={value}
                focused={focused}
                setFocused={setFocused}
                therp={therp}
                setTherp={setTherp}
                videoQuality={videoQuality}
                setVideoQuality={setVideoQuality}
              /> );
          }
          if ( type === 'roomDetails' )
          {
            return ( <RoomDetails
              roomDetails={roomDetails}
              selected={selected}
            /> );
          }
          if ( type === "Feedback" )
          {
            return ( <Feedback
              feedType={feedType}
              selected={selected}
              patientName={patientName}
              handleSubmit={feedBack}
            /> );
          }
          if ( type === 'feedList' )
          {
            return (
              <FeedBackList
                feedbackList={feedbackList}
              />
            );
          }
        } )()}
      </Modal>
    </Layout >
  );
};
